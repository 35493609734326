
/** Import of the Ant Design Library; **/
@import '~antd/dist/antd.css';

/** Import of Google Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/** Body of the App **/
body {
  font-family: 'Roboto', sans-serif;
}


/** All divs **/
div{
  font-family: 'Roboto', sans-serif;
}


.ant-menu.upbar {
  font-family: 'Roboto', sans-serif;
  padding: 1px;

}

.ant-layout.lay {
  font-family: 'Roboto', sans-serif;

  height: 100vh;
  background: #fff;
}

.site-layout-content {
  min-height: 100vh;
  padding: 30px;
  background: #fff;
}

.site-layout-background{


  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 40px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-card.def_card  {
  border-color: #F94D5A;
  border-width: 2px;
  width: 250px;
  text-align: center;
  padding: 1px;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;

}

/** All cards **/
.ant-card{
  font-family: 'Roboto', sans-serif;

}

.ant-card.list_card{
  border: 1px solid rgba(150, 150, 156, 0.418);
  height: 45px;
  font-family: 'Roboto', sans-serif;
  text-align: center;


}
.ant-card.list_item{
  height: 45px;

  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  text-align: center;


}
.ant-card.list_menu{
  border: 0;

  font-family: 'Roboto', sans-serif;
  text-align: center;

}


.ant-card.list_menu_inicio{
  border: 1px solid rgba(182, 182, 185, .3);
  height: 45px;
  max-width: 700px;

  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  text-align: center;


}
.ant-card.list_menu_inicio_menu{
  border: 0px ;
  height: 45px;
  max-width: 700px;

  font-family: 'Roboto', sans-serif;
  text-align: center;


}

.ant-card-body{
 padding-top: 10px;


}

.perfil{
  padding-top: 100px;
  padding-left: 200px;

}


.ant-col.list_nome {
  text-align:left

}

.ant-col.list_acao {
  text-align:right

}
.ant-col.list_badge {
  align-self: center;
}
.ant-row.linha{
  padding-top: 30px;
}

.carregar{
    padding-top: 25px;
    text-align: right;
}

.ant-modal-content{
  width: 90vh;
}

.ant-modal{
  top: 50px;
  left: -150px;
}

.login{
  font-family: 'Roboto', sans-serif;
  width: fit-content;
  padding-top: 25vh;
  margin: auto;
  text-align: center;
}


.footer {
  width: 100%;
  bottom: 0;
  text-align: center;
  margin-top: 80px;
  position: absolute;

}


.login_card{
  border: 1px solid rgba(51, 51, 56, 0.3);
  padding: 20px;
  border-radius: 5px;
  background-color: rgba(158, 158, 160, 0.041);

}

.estatisticas{
  padding-top: 50px;
  padding-bottom: 50px;
  align-items: center;
  text-align: center;
}

.inicio_proposta{
  padding-bottom: 20px;
}


.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  font-weight: bold;
  color: #F94D5A;
  text-align: center


}
.ant-pagination-item-active{
  border-color: #F94D5A;

}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
   border-bottom: 2px solid #F94D5A;
   font-weight: bold;
  color: #F94D5A;
  text-align: center

}

.inicio{
  text-align:left;
  left: 0;

}
.inicio_u{
  text-align:right;
  left: 0;

}

.ant-card.card_est{
  width: 300px;
  height: 250px;
  right: 0;


}

.list_nova_proposta{
  padding-top: 25px;
}

::selection {
  color: #fff;
  background: #F94D5A;
}


.man_ajuda{
  padding-top: 50px;
  align-items: center;
  text-align: center;
}

.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
  color: black;

}

.ant-btn:active, .ant-btn , .ant-btn:hover {
  color:#F94D5A;
  border-color: #F94D5A;
  background: #fff;
}

 .ant-menu-submenu-title:hover, .ant-menu-submenu-open.menu_item  {
  background: #f3bfc3c2;
}

.ant-menu-submenu-arrow,.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
  background: #F94D5A;
  color: #F94D5A;
}

.ant-input:hover, .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover  {
    border-color:#F94D5A;
    border-right-width: 1px;
}
.ant-input:focus, .ant-input-focused, .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
  border-color: #F94D5A;
  box-shadow: 0 0 0 2px #f94d5b62;
  border-right-width: 1px;
  outline: 0;
}


